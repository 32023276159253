export const infuraKey = 'db392d0858024a89896bac0af03f41ac';//'b87b96a22d544319809fa30f8405f44d' //process.env.REACT_APP_INFURA_KEY

export const collectionAddress = '0x77e7c998bf133faa2b1f1b26f0144f87bdf583ac';//'0xBc0548D6E0AfF82ED44b82Bf2FD02E4E0F2f5303';//'0x0b2465c85a00efb726c5a8cbbcafbe8538a74dda'; // Collection of NFTS
export const factoryAddress = '0xc9EBD215db364aE635C08dcd45653995c7324b82';//'0x056733a157093e9fd3237522c0ff23fddf402007'; // Deployed Contract for staking
export const createPoolOwner = '0xC927664Eee63B67b4135c6164a1Cd329019C1Ce3';//'0x97A520b634Ea785373658E97D1Aaa6C6ee7D5FC1'; // Dev Wallet who will fund the pool.
export const paginationLimit = 24;

export const routerPcsAddress = '0x10ed43c718714eb63d5aa57b78b54704e256024e';
export const ETHAddress = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
export const bnbAddress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';

export const infuraAddress = `https://mainnet.infura.io/v3/${infuraKey}`
export const bscNodeAddress = `https://bsc-dataseed.binance.org/`

export const allowedChains = [1,3,56,8453]

export const linuTokenAddress = ''

export const allowBalance = 0;