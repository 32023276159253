import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Header from '../components/shared/layout/header/Header';
import { ThemeProviderEnum, themeVar } from '../variables/Shared'
import { Page404 } from '../pages/Page404'
import {AppContext} from '../contexts'

const HomePage = lazy(() => import('../pages/HomePage'))
const NftStakePage = lazy(() => import('../pages/NftStakePage'))
const CreatePoolPage = lazy(() => import('../pages/CreatePoolPage'))
const TokenStakePage = lazy(() => import('../pages/TokenStakePage'))
const DashboardPage = lazy(() => import('../pages/DashboardPage'))
const PublicMintPage = lazy(() => import('../pages/PublicMintPage'))
const SniperPage = lazy(() => import('../pages/SniperPage'))
const SwapPage = lazy(() => import('../pages/SwapPage'))
const CrosschainPage = lazy(() => import('../pages/CrosschainPage'))
const MultichartPage = lazy(() => import('../pages/MultichartPage'))
const RugcheckerPage = lazy(() => import('../pages/RugcheckerPage'))
const WatchlistPage = lazy(() => import('../pages/WatchlistPage'))
const TrackerWalletPage = lazy(() => import('../pages/TrackerWalletPage'))

export default function Routes() {
  const [theme, setTheme] = useState({theme: localStorage.getItem('theme')})

  useEffect(() => {
    if (theme.theme === 'dark') {
      themeVar(ThemeProviderEnum.dark)
    } else {
      themeVar(ThemeProviderEnum.light)
    }
  }, [theme])

  return (    
      <Suspense fallback={<Header />}>
        <AppContext.Provider value={{theme, setTheme}}>
          <Switch>
            <Route path='/' exact component={HomePage} />
            <Route path='/mint' exact component={PublicMintPage} />
            <Route path='/stake' exact component={NftStakePage} />
            <Route path='/pool' exact component={CreatePoolPage} />
            <Route path='/tokenstake' exact component={TokenStakePage} />
            <Route path='/dashboard' exact component={DashboardPage} />
            <Route path='/sniper' exact component={SniperPage} />
            <Route path='/swap' exact component={SwapPage} />
            <Route path='/crosschain' exact component={CrosschainPage} />
            <Route path='/multichart' exact component={MultichartPage} />
            <Route path='/rugchecker' exact component={RugcheckerPage} />
            <Route path='/whatchlist' exact component={WatchlistPage} />
            <Route path='/trackerwallet' exact component={TrackerWalletPage} />
            <Route path='**' component={Page404} />
          </Switch>
        </AppContext.Provider>
      </Suspense>
  )
}
